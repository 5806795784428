@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in {
    animation: fadeIn 1.5s ease-in-out;
  }

  /* Estilos globales o dentro de tu archivo title.css */
.container {
  overflow: hidden;
}

img {
  max-width: 100%; /* Asegura que la imagen no se desborde en dispositivos pequeños */
  height: auto; /* Mantiene la proporción de la imagen */
}
